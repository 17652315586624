import classNames from 'classnames/bind';
import styles from './App.module.scss';

import { AppHeader } from './components/AppHeader';
import { AppIntro } from './components/AppIntro';
import { About } from './components/About';
import { Partners } from './components/Partners';
import { Video } from './components/Video';
import { Contact } from './components/Contact';
import { AppFooter } from './components/AppFooter';
import { useMediaQuery } from 'react-responsive';
import { useEffect, useState } from 'react';

const cx = classNames.bind(styles);

export function App() {
  const [viewState, setViewState] = useState('');

  const isDesktop: boolean = useMediaQuery({
    query: '(min-width:1500px)',
  });
  const isLaptop: boolean = useMediaQuery({
    query: '(min-width:1201px) and (max-width:1499px)',
  });
  const isTablet: boolean = useMediaQuery({
    query: '(min-width:768px) and (max-width:1200px)',
  });
  const isMobile: boolean = useMediaQuery({
    query: '(max-width:767px)',
  });

  const [currentNav, setCurrentNav] = useState('');
  const [navState, setNavState] = useState(false);
  const [scrollY, setScrollY] = useState<number | undefined>(0);

  useEffect(() => {
    return () => {
      window.scrollTo({ top: scrollY });
    };
  }, [navState]);

  useEffect(() => {
    if (isDesktop) {
      setViewState('desktop');
    } else if (isLaptop) {
      setViewState('laptop');
    } else if (isTablet) {
      setViewState('tablet');
    } else if (isMobile) {
      setViewState('mobile');
    }
  }, [isDesktop, isLaptop, isTablet, isMobile]);

  return (
    <div className={cx('container')} data-temp={scrollY}>
      <AppHeader
        currentNav={currentNav}
        viewState={viewState}
        navState={navState}
        setNavState={setNavState}
        setScrollY={setScrollY}
      />
      {!navState && (
        <>
          <div className={cx('content')}>
            <AppIntro setCurrentNav={setCurrentNav} />
            <About setCurrentNav={setCurrentNav} viewState={viewState} />
            {/* 2023/01/25 동영상 삭제 <Video /> */}
            <Contact setCurrentNav={setCurrentNav} />
          </div>
          <AppFooter />
        </>
      )}
    </div>
  );
}
