import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './Contact.module.scss';

const cx = classNames.bind(styles);

interface Props {
  setCurrentNav: (state: string) => void;
}

export function Contact({ setCurrentNav }: Props) {
  const { ref: anchor, inView: anchorView } = useInView({
    threshold: 1,
  });

  useEffect(() => {
    if (anchorView) {
      setCurrentNav('contact');
    }
  }, [anchorView]);
  return (
    <section className={cx('contact')} ref={anchor}>
      <h2 id="contact" className={cx('anchor')}>
        CONTACT US
      </h2>
      <div className={cx('section-title')}>
        <h2 className={cx('subject')}>Ready to get on board?</h2>
        <p className={cx('description')}>
          Learn how Intella and its services can help bootstrap your projects to
          achieve their innovative potentials.
        </p>
        <a href="mailto:contact@intellax.io" className={cx('btn-link')}>
          contact us
        </a>
      </div>
      <article className={cx('contact-content')}></article>
    </section>
  );
}
