import classNames from 'classnames/bind';
import styles from './AppIntro.module.scss';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

const cx = classNames.bind(styles);

interface Props {
  setCurrentNav: (state: string) => void;
}

export function AppIntro({ setCurrentNav }: Props) {
  const { ref: anchor, inView: anchorView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (anchorView) {
      setCurrentNav('intro');
    }
  }, [anchorView]);

  return (
    <section className={cx('intro')} ref={anchor}>
      <h2 id="company" className={cx('anchor')}>
        COMPANY
      </h2>
      <article className={cx('intro-content')}>
        <i className={cx('icon')}></i>
        <span className={cx('title')}>
          Pioneering Web3,
          <br />
          through innovative applications.
        </span>
        <p className={cx('caption')}>
          Pioneering the Web3 space with innovative services and approaches to
          break down
          <br />
          high-entry barriers while empowering game developers and players in
          Web3.
        </p>
      </article>
    </section>
  );
}
