import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import styles from './AppHeader.module.scss';

const cx = classNames.bind(styles);

interface Props {
  currentNav: string;
  viewState: string;
  navState: boolean;
  setScrollY: (top: number | undefined) => void;
  setNavState: (state: boolean) => void;
}

export function AppHeader({
  currentNav,
  viewState,
  navState,
  setNavState,
  setScrollY,
}: Props) {
  function navClick() {
    if (viewState == 'mobile' || viewState == 'tablet') {
      setNavState(false);
    }
  }

  useEffect(() => {
    if (viewState != 'mobile' && viewState != 'tablet') {
      setNavState(false);
    }
  }, [viewState]);

  return (
    <header
      className={cx('header')}
      data-nav-state={navState}
      data-temp={viewState}
    >
      <div className={cx('logo')}>
        <h1 className={cx('novaflow-labs')}>
          <a href="/" className={cx('link')}>
            NOVAFLOW labs
          </a>
        </h1>
      </div>

      {viewState == 'tablet' || viewState == 'mobile' ? (
        <button
          type="button"
          className={cx('btn-menu-action')}
          onClick={() => {
            if (!navState) {
              setScrollY(window.scrollY);
            }
            setNavState(!navState);
          }}
        >
          Menu
        </button>
      ) : (
        <></>
      )}

      <div className={cx('gnb')}>
        <nav className={cx('navigator')}>
          <ul className={cx('list')}>
            <li className={cx('item')} data-active={currentNav == 'intro'}>
              <a href="#company" className={cx('link')} onClick={navClick}>
                HOME
              </a>
            </li>
            <li className={cx('item')} data-active={currentNav == 'about'}>
              <a href="#about" className={cx('link')} onClick={navClick}>
                PRODUCTS / DEVELOPMENT
              </a>
            </li>
            <li className={cx('item')} data-active={currentNav == 'contact'}>
              <a href="#contact" className={cx('link')} onClick={navClick}>
                CONTACT US
              </a>
            </li>
            {/* <li className={cx('item')}>
              <a
                href="https://www.neowiz.com/neowiz/careers"
                className={cx('link')}
                target="_blank"
                onClick={navClick}
              >
                CAREER
              </a>
            </li> */}
          </ul>
        </nav>
      </div>
    </header>
  );
}
