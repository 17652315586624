import classNames from 'classnames/bind';
import styles from './AppFooter.module.scss';

const cx = classNames.bind(styles);

interface Props {}

export function AppFooter({}: Props) {
  return (
    <footer className={cx('footer')}>
      <div className={cx('footer-content')}>
        <nav className={cx('footer-navigator')}>
          <ul className={cx('list')}>
            <li className={cx('item')}>
              <a href="/" className={cx('link')}>
                HOME
              </a>
            </li>
            <li className={cx('item')}>
              <a href="#partners" className={cx('link')}>
                PARTNERS
              </a>
            </li>
            <li className={cx('item')}>
              <a href="mailto:contact@intellax.io" className={cx('link')}>
                CONTACT US
              </a>
            </li>
            {/* <li className={cx('item')}>
              <a
                href="https://www.neowiz.com/neowiz/careers"
                className={cx('link')}
                target="_blank"
              >
                CAREER
              </a>
            </li> */}
          </ul>
        </nav>
        <div className={cx('footer-logo')}>
          <div className={cx('novaflow-labs')}>NOVAFLOW labs</div>
        </div>
        <p className={cx('copy')}>
          &copy; Novaflow Labs Ltd. All rights reserved.
        </p>
      </div>
    </footer>
  );
}
