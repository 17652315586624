import classNames from 'classnames/bind';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import styles from './About.module.scss';

const cx = classNames.bind(styles);

interface Props {
  setCurrentNav: (state: string) => void;
  viewState: string;
}

export function About({ setCurrentNav, viewState }: Props) {
  const { ref: anchor, inView: anchorView } = useInView({
    threshold: 0.5,
  });

  useEffect(() => {
    if (anchorView) {
      setCurrentNav('about');
    }
  }, [anchorView]);

  return (
    <section className={cx('about')} ref={anchor}>
      <h2 id="about" className={cx('anchor')}>
        ABOUT US
      </h2>
      <div className={cx('section-title')}>
        <h2 className={cx('subject')}>Web3 is here, and we are here to help</h2>
        <p className={cx('description')}>
          Intella is disrupting the Web3 gaming industry through Intella X
          {viewState == 'mobile' ? <br /> : <>&nbsp;</>}
          Web3 gaming platform built on Polygon, a unique gaming platform
          {viewState != 'mobile' ? <br /> : <>&nbsp;</>}
          co-developed by Intella and NEOWIZ Corporation. The platform is
          established for game developers and users by{' '}
          <em>game and platform developers</em> themselves to offer robust Web3
          {viewState != 'mobile' ? <br /> : <>&nbsp;</>}
          services that cater to Web2 game developers on their journey to Web3.
        </p>
      </div>
      <article className={cx('about-content')}>
        <ul className={cx('list')}>
          <li className={cx('item')} data-item="0">
            <div className={cx('icon')}></div>
            <div className={cx('cont')}>
              <span className={cx('subject')}>Intella X Wallet</span>
              {viewState != 'desktop' ? (
                <>
                  <span className={cx('text')}>
                    The IX Wallet is a Web3-based <br />
                    wallet optimized for game <br />
                    services for the Intella X <br />
                    platform.
                  </span>
                </>
              ) : (
                <>
                  <span className={cx('text')}>
                    The IX Wallet is a Web3-based wallet optimized
                    <br />
                    for game services for the Intella X platform.
                  </span>
                </>
              )}
            </div>
          </li>
          <li className={cx('item')} data-item="1">
            <div className={cx('icon')}></div>
            <div className={cx('cont')}>
              <span className={cx('subject')}>Intella X Marketplace</span>
              <span className={cx('text')}>
                {viewState != 'desktop' ? (
                  <>
                    <span className={cx('text')}>
                      The marketplace supports <br />
                      P2P trading, allowing game <br />
                      users to buy and sell NFTs <br />
                      with other users in real-
                      <br />
                      time.
                    </span>
                  </>
                ) : (
                  <>
                    <span className={cx('text')}>
                      The marketplace supports P2P trading,
                      <br />
                      allowing game users to buy and sell NFTs
                      <br />
                      with other users in real-time.
                    </span>
                  </>
                )}
              </span>
            </div>
          </li>
          <li className={cx('item')} data-item="2">
            <div className={cx('icon')}></div>
            <div className={cx('cont')}>
              <span className={cx('subject')}>Intella X DEX</span>
              <span className={cx('text')}>
                {viewState != 'desktop' ? (
                  <>
                    <span className={cx('text')}>
                      TThe proprietary DEX has <br />
                      been designed exclusively <br />
                      for the Intella X platform.
                    </span>
                  </>
                ) : (
                  <>
                    <span className={cx('text')}>
                      The proprietary DEX has been designed
                      <br />
                      exclusively for the Intella X platform.
                    </span>
                  </>
                )}
              </span>
            </div>
          </li>
          <li className={cx('item')} data-item="3">
            <div className={cx('icon')}></div>
            <div className={cx('cont')}>
              <span className={cx('subject')}>Intella X Launchpad</span>
              <span className={cx('text')}>
                {viewState != 'desktop' ? (
                  <>
                    <span className={cx('text')}>
                      Launchpad in Intella X is the <br />
                      accelerator/incubation program <br />
                      that assists in bootstrapping <br />
                      upcoming projects' entry into <br />
                      the ecosystem.
                    </span>
                  </>
                ) : (
                  <>
                    <span className={cx('text')}>
                      Launchpad in Intella X is the accelerator/incubation
                      <br />
                      program that assists in bootstrapping upcoming
                      <br />
                      projects' entry into the ecosystem.
                    </span>
                  </>
                )}
              </span>
            </div>
          </li>
        </ul>

        {/* <ul className={cx('list')}>
          <li className={cx('item')} data-item="0">
            Proprietary Web3 services as well as
            {viewState == 'tablet' || viewState == 'mobile' ? (
              <br />
            ) : (
              <>&nbsp;</>
            )}
            customized technical integration
            <br />
            experience for game developers on
            {viewState == 'tablet' || viewState == 'mobile' ? (
              <br />
            ) : (
              <>&nbsp;</>
            )}
            the platform.
          </li>
          <li className={cx('item')} data-item="1">
            Simple and easy Web3 integration
            <br />
            through Intella X platform.
          </li>
          <li className={cx('item')} data-item="2">
            Game developers can focus on what they do
            {viewState == 'mobile' ? <br /> : <>&nbsp;</>}
            best—creating high-quality content.
            <br />
            Intella X handles the client’s needs and
            {viewState == 'mobile' ? <br /> : <>&nbsp;</>}
            wants in their transition to Web3.
          </li>
        </ul> */}
      </article>
    </section>
  );
}
